import React from "react";
import { Button } from "react-bootstrap";

const ButtonWithSpinner = ({
  saving,
  buttonText = "Save",
  savingText = "Saving",
  faIcon = null,
  ...other
}) => {
  if (saving) {
    return (
      <Button disabled={true} {...other}>
        <i className="fa fa-circle-notch fa-spin mr-1" />
        {savingText}
      </Button>
    );
  } else {
    return (
      <Button disabled={false} {...other}>
        {faIcon && (
          <i className={`fa ${faIcon ? `fa-${faIcon}` : ""} margin-right-5`} />
        )}
        {buttonText}
      </Button>
    );
  }
};

export default ButtonWithSpinner;
