import React from "react";
import _ from "lodash";

import FormStructure from "services/structure/form_structure";
import DependencyChecker from "services/dependency_checker";

class ProspectFormData {
  constructor(formStructure, lookupStructure, formData) {
    this.formStructure = new FormStructure(formStructure);
    this.lookupStructure = lookupStructure;
    this.formData = formData;
    this.dependencyChecker = new DependencyChecker(this);
  }

  getFieldFor(fieldIdentifier, subformIdentifier = undefined) {
    if (subformIdentifier == undefined) {
      return this.formStructure.getFieldFromIdentifier(fieldIdentifier);
    } else {
      return this.formStructure
        .getSubformFromIdentifier(subformIdentifier)
        .getFieldFromIdentifier(fieldIdentifier);
    }
  }

  getSubformFor(subformIdentifier) {
    return this.formStructure.getSubformFromIdentifier(subformIdentifier);
  }

  getSubformRowsFor(subformIdentifier) {
    return this.formData[subformIdentifier];
  }

  getFieldDataFor(
    fieldIdentifier,
    subformIdentifier = undefined,
    rowIndex = 0
  ) {
    if (subformIdentifier === undefined) {
      return this.formData[fieldIdentifier];
    } else {
      return this.formData[subformIdentifier][rowIndex].subformData[
        fieldIdentifier
      ];
    }
  }

  getValueFor(fieldIdentifier, subformIdentifier = undefined, rowIndex = 0) {
    return this.getFieldDataFor(fieldIdentifier, subformIdentifier, rowIndex)
      .value;
  }

  getContentFor(fieldIdentifier, subformIdentifier = undefined, rowIndex = 0) {
    const fieldStructure = this.getFieldFor(fieldIdentifier, subformIdentifier);
    const fieldValue = this.getValueFor(
      fieldIdentifier,
      subformIdentifier,
      rowIndex
    );

    switch (fieldStructure.type) {
      case "enum":
        const selectedLookup = this.lookupStructure.find(
          (lookup) => lookup.identifier == fieldStructure.lookup
        );
        const selectedMapping = selectedLookup
          ? selectedLookup.mapping.find(
              (mapping) => mapping.value == fieldValue
            )
          : undefined;
        if (selectedMapping) return selectedMapping.label;
        break;
      case "date":
        return fieldValue instanceof Date
          ? fieldValue.toLocaleDateString("en-gb")
          : fieldValue;
      case "boolean":
        return (
          <span>
            <i
              className={`fal ${
                fieldValue ? "fa-check-square" : "fa-square"
              } mr-1`}
            />
            {fieldStructure.label}
          </span>
        );
      default:
        return fieldValue;
    }
  }

  isFieldVisible = (
    fieldStructure,
    subformIdentifier = undefined,
    rowIndex = 0
  ) => {
    return this.dependencyChecker.dependenciesMet(
      fieldStructure.dependencies,
      subformIdentifier,
      rowIndex
    );
  };

  isSubformVisible = (subformStructure) => {
    return this.dependencyChecker.dependenciesMet(
      subformStructure.dependencies
    );
  };
}

export default ProspectFormData;
