import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actions } from "actions/questionnaire_completion_log";
import ProspectFormData from "services/prospect_form_data";

import { Alert } from "react-bootstrap";
import ProspectForm from "components/prospect_form/ProspectForm";
import { ConfirmationModal } from "components/helpers";

const EditableCompletionLog = ({
  prospectFormData,
  baseErrors,
  confirmationModalState,
  newOrEdit,
  saving,
  cancelPath,
  studyIdentifier,
  individualIdentifier,
  eventDefinitionIdentifier,
  questionnaireIdentifier,
  updateFormValue,
  updateSubformValue,
  addSubformRow,
  removeSubformRow,
  hideConfirmationModal,
  confirmSubformRowRemoval,
  newCompletionLogSubmit,
  editCompletionLogSubmit,
}) => {
  const onSubmit = (values) => {
    if (newOrEdit === "new") {
      newCompletionLogSubmit(
        studyIdentifier,
        individualIdentifier,
        eventDefinitionIdentifier,
        questionnaireIdentifier,
        values
      );
    } else {
      editCompletionLogSubmit(
        studyIdentifier,
        individualIdentifier,
        eventDefinitionIdentifier,
        questionnaireIdentifier,
        values
      );
    }
  };

  return (
    <>
      {baseErrors.length > 0 && (
        <Alert variant="danger">
          {baseErrors.map((error, index) => (
            <p key={`base-error-${index}`}>{error}</p>
          ))}
        </Alert>
      )}
      <ConfirmationModal
        show={confirmationModalState.show}
        modalBody={<p>{confirmationModalState.message}</p>}
        onHide={hideConfirmationModal}
        onConfirm={() =>
          confirmSubformRowRemoval(
            confirmationModalState.details.subformIdentifier,
            confirmationModalState.details.rowNum
          )
        }
      />
      <ProspectForm
        editable={true}
        prospectFormData={prospectFormData}
        saving={saving}
        cancelPath={cancelPath}
        onFieldChange={updateFormValue}
        onSubformFieldChange={updateSubformValue}
        addSubformRow={addSubformRow}
        removeSubformRow={removeSubformRow}
        onSubmit={onSubmit}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  let relevantState = state.completionLogForm;

  return {
    prospectFormData: new ProspectFormData(
      relevantState.formStructure,
      relevantState.lookupStructure,
      relevantState.formData
    ),
    baseErrors: relevantState.baseErrors,
    confirmationModalState: relevantState.confirmationModal,
    confirmationModalShow: relevantState.confirmationModal.show,
    confirmationModalMessage: relevantState.confirmationModal.message,
    newOrEdit: relevantState.newOrEdit,
    saving: relevantState.saving,
    studyIdentifier: relevantState.studyIdentifier,
    individualIdentifier: relevantState.individualIdentifier,
    eventDefinitionIdentifier: relevantState.eventDefinitionIdentifier,
    questionnaireIdentifier: relevantState.questionnaireIdentifier,
    cancelPath: `/studies/${relevantState.studyIdentifier}/individuals/${relevantState.individualIdentifier}/questionnaires`,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateFormValue: actions.updateFormValue,
      updateSubformValue: actions.updateSubformValue,
      addSubformRow: actions.addSubformRow,
      removeSubformRow: actions.removeSubformRow,
      hideConfirmationModal: actions.hideConfirmationModal,
      confirmSubformRowRemoval: actions.confirmSubformRowRemoval,
      newCompletionLogSubmit: actions.newCompletionLogSubmit,
      editCompletionLogSubmit: actions.editCompletionLogSubmit,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditableCompletionLog);
