import React from "react";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { Provider } from "react-redux";

import * as Sentry from "@sentry/react";
import { ErrorFallback } from "components/helpers";

import {
  CompletionLogFormReducer,
  completionLogFormDefaultState,
  loadFormDataFrom,
} from "reducers/completionLogFormReducer.reducer";
import { CompletionLogFormMiddleware } from "middleware/CompletionLogFormMiddleware.middleware";

import EditableCompletionLog from "components/questionnaire_completion_log/EditableCompletionLog";

const EditableQuestionnaireCompletionLogPage = ({
  newOrEdit,
  studyIdentifier,
  individualIdentifier,
  eventDefinitionIdentifier,
  questionnaireIdentifier,
  completionLogData,
}) => {
  const store = createStore(
    combineReducers({
      completionLogForm: CompletionLogFormReducer,
    }),
    {
      completionLogForm: {
        ...completionLogFormDefaultState,
        newOrEdit: newOrEdit,
        studyIdentifier: studyIdentifier,
        individualIdentifier: individualIdentifier,
        eventDefinitionIdentifier: eventDefinitionIdentifier,
        questionnaireIdentifier: questionnaireIdentifier,
        formData: loadFormDataFrom(completionLogData),
      },
    },
    applyMiddleware(CompletionLogFormMiddleware)
  );

  return (
    <Provider store={store}>
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        <EditableCompletionLog />
      </Sentry.ErrorBoundary>
    </Provider>
  );
};

export default EditableQuestionnaireCompletionLogPage;
