import { ActionTypes } from "actions/questionnaire_completion_log";

import {
  questionnaireCompletionLogStructure,
  questionnaireCompletionLogLookups,
} from "services/questionnaire_completion_log_structure";
import ProspectFormData from "services/prospect_form_data";

export const completionLogFormDefaultState = {
  saving: false,
  formStructure: questionnaireCompletionLogStructure,
  lookupStructure: questionnaireCompletionLogLookups,
  baseErrors: [],
  confirmationModal: {
    show: false,
    message: "",
    details: {},
  },
  formData: {
    qst_sent_yn: {
      value: null,
      hidden: false,
      errors: [],
    },
    no_qst_sent_rsn: {
      value: "",
      hidden: true,
      errors: [],
    },
    qst_sent_dt: {
      value: null,
      hidden: true,
      errors: [],
    },
    qst_sent_meth: {
      value: null,
      hidden: true,
      errors: [],
    },
    reminder_sent: {
      value: true,
      hidden: true,
      errors: [],
    },
    qst_comp_yn: {
      value: null,
      hidden: false,
      errors: [],
    },
    no_qst_comp_rsn: {
      value: "",
      hidden: true,
      errors: [],
    },
    qst_rec_dt: {
      value: null,
      hidden: true,
      errors: [],
    },
    qst_comp_dt: {
      value: null,
      hidden: true,
      errors: [],
    },
    no_comp_dt: {
      value: null,
      hidden: true,
      errors: [],
    },
    qst_comp_meth: {
      value: null,
      hidden: true,
      errors: [],
    },
  },
};

const blankContactLogRow = {
  persisted: false,
  id: "",
  marked_for_destruction: false,
  subformData: {
    reminder_dt: {
      value: null,
      hidden: false,
      errors: [],
    },
    reminder_meth: {
      value: null,
      hidden: false,
      errors: [],
    },
    reminder_yn: {
      value: null,
      hidden: false,
      errors: [],
    },
    no_reminder_rsn: {
      value: "",
      hidden: true,
      errors: [],
    },
  },
};

const refreshDependencies = (
  formData,
  formStructure = questionnaireCompletionLogStructure
) => {
  const prospectFormData = new ProspectFormData(
    formStructure,
    questionnaireCompletionLogLookups,
    formData
  );

  const newFormData = Object.fromEntries(
    Object.entries(formData).map(([key, value]) => {
      if (key == "reminder_logs") {
        const subformIdentifier = key;
        const newContactLogs = value.map((row, index) => {
          const newSubformData = Object.fromEntries(
            Object.entries(row.subformData).map(([key, value]) => {
              const field = prospectFormData.getFieldFor(
                key,
                subformIdentifier
              );
              return [
                key,
                {
                  ...value,
                  hidden: !prospectFormData.isFieldVisible(
                    field,
                    subformIdentifier,
                    index
                  ),
                },
              ];
            })
          );
          return {
            ...row,
            hidden: !prospectFormData.isSubformVisible(
              prospectFormData.getSubformFor(subformIdentifier)
            ),
            subformData: newSubformData,
          };
        });
        return [key, newContactLogs];
      }

      const field = prospectFormData.getFieldFor(key);
      return [
        key,
        { ...value, hidden: !prospectFormData.isFieldVisible(field) },
      ];
    })
  );
  return newFormData;
};

export const loadFormDataFrom = (completionLogData) => {
  const loadValueForField = (field, dataSource) => {
    switch (field.type) {
      case "text": {
        return dataSource[field.identifier] || "";
      }
      case "date": {
        return dataSource[field.identifier]
          ? new Date(dataSource[field.identifier])
          : null;
      }
      case "boolean": {
        return dataSource[field.identifier] || false;
      }
      default: {
        return dataSource[field.identifier];
      }
    }
  };

  const contactLogData = completionLogData.reminder_logs.map((row) => {
    const subformDataWithoutDependencies = Object.fromEntries(
      questionnaireCompletionLogStructure.subforms[0].fields.map((field) => {
        return [
          field.identifier,
          { value: loadValueForField(field, row), errors: [] },
        ];
      })
    );
    return {
      ...blankContactLogRow,
      id: row.id,
      persisted: true,
      row_num: row.row_num,
      subformData: subformDataWithoutDependencies,
    };
  });

  const formDataWithoutDependencies = Object.fromEntries(
    questionnaireCompletionLogStructure.fields.map((field) => {
      return [
        field.identifier,
        {
          value: loadValueForField(field, completionLogData),
          errors: [],
        },
      ];
    })
  );
  const formData = refreshDependencies({
    ...formDataWithoutDependencies,
    reminder_logs: contactLogData,
  });

  return formData;
};

export const CompletionLogFormReducer = (
  state = completionLogFormDefaultState,
  action
) => {
  switch (action.type) {
    case ActionTypes.HIDE_CONFIRMATION_MODAL: {
      const newState = {
        ...state,
        confirmationModal: {
          show: false,
          message: "",
          details: {},
        },
      };
      return newState;
    }

    case ActionTypes.UPDATE_FORM_VALUE: {
      const newFormData = refreshDependencies({
        ...state.formData,
        [action.fieldIdentifier]: {
          ...state.formData[action.fieldIdentifier],
          value: action.value,
        },
      });
      const newState = {
        ...state,
        formData: newFormData,
      };
      return newState;
    }

    case ActionTypes.UPDATE_SUBFORM_VALUE: {
      const newSubformRows = state.formData[action.subformIdentifier].map(
        (row, index) => {
          if (index === action.rowNum) {
            return {
              ...row,
              subformData: {
                ...row.subformData,
                [action.fieldIdentifier]: {
                  ...row.subformData[action.fieldIdentifier],
                  value: action.value,
                },
              },
            };
          } else {
            return row;
          }
        }
      );

      const newState = {
        ...state,
        formData: refreshDependencies({
          ...state.formData,
          [action.subformIdentifier]: newSubformRows,
        }),
      };
      return newState;
    }

    case ActionTypes.ADD_SUBFORM_ROW: {
      const newState = {
        ...state,
        formData: {
          ...state.formData,
          [action.subformIdentifier]: [
            ...state.formData[action.subformIdentifier],
            {
              ...blankContactLogRow,
              row_num: state.formData[action.subformIdentifier].filter(
                (row) => row.marked_for_destruction === false
              ).length,
            },
          ],
        },
      };
      return newState;
    }

    case ActionTypes.REMOVE_SUBFORM_ROW: {
      const newState = {
        ...state,
        confirmationModal: {
          show: true,
          message: `Are you sure you want to delete contact log row ${
            action.rowNum + 1
          }?`,
          details: {
            subformIdentifier: action.subformIdentifier,
            rowNum: action.rowNum,
          },
        },
      };
      return newState;
    }

    case ActionTypes.CONFIRM_SUBFORM_ROW_REMOVAL: {
      const selectedRow = state.formData[action.subformIdentifier].find(
        (row, index) => index === action.rowNum
      );

      const updateRowNums = (subformRows) => {
        let currentRowNum = 0;
        return subformRows.map((row, index) => {
          if (!row.marked_for_destruction) {
            const newRow = { ...row, row_num: currentRowNum };
            currentRowNum += 1;
            return newRow;
          } else {
            return { ...row, row_num: null };
          }
        });
      };

      let newFormData;
      if (selectedRow.persisted === true) {
        const newSubformRows = state.formData[action.subformIdentifier].map(
          (row, index) => {
            if (index === action.rowNum) {
              return { ...row, marked_for_destruction: true };
            } else {
              return row;
            }
          }
        );
        newFormData = {
          ...state.formData,
          [action.subformIdentifier]: updateRowNums(newSubformRows),
        };
      } else {
        const newSubformRows = state.formData[action.subformIdentifier].filter(
          (row, index) => {
            return index !== action.rowNum;
          }
        );
        newFormData = {
          ...state.formData,
          [action.subformIdentifier]: updateRowNums(newSubformRows),
        };
      }

      const newState = {
        ...state,
        confirmationModal: {
          show: false,
          message: "",
          details: {},
        },
        formData: newFormData,
      };
      return newState;
    }

    case ActionTypes.NEW_COMPLETION_LOG_SUBMIT:
    case ActionTypes.EDIT_COMPLETION_LOG_SUBMIT: {
      const newState = {
        ...state,
        saving: true,
      };
      return newState;
    }

    case ActionTypes.NEW_COMPLETION_LOG_SUBMIT_FAILURE:
    case ActionTypes.EDIT_COMPLETION_LOG_SUBMIT_FAILURE: {
      const addErrors = (errors, fieldIdentifier, fieldData) => {
        if (errors[fieldIdentifier]) {
          return [
            fieldIdentifier,
            { ...fieldData, errors: errors[fieldIdentifier] },
          ];
        } else {
          return [fieldIdentifier, { ...fieldData, errors: [] }];
        }
      };

      const newContactLogs = state.formData.reminder_logs.map((row, index) => {
        return {
          ...row,
          subformData: Object.fromEntries(
            Object.entries(row.subformData).map(([key, value]) => {
              return addErrors(
                action.responseData.subformErrors.reminder_logs[index],
                key,
                value
              );
            })
          ),
        };
      });

      const newFormData = Object.fromEntries(
        Object.entries(state.formData).map(([key, value]) => {
          if (key === "reminder_logs") {
            return [key, newContactLogs];
          } else {
            return addErrors(action.responseData.formErrors, key, value);
          }
        })
      );
      const newState = {
        ...state,
        saving: false,
        baseErrors: action.responseData.baseErrors,
        formData: newFormData,
      };
      return newState;
    }

    case ActionTypes.NEW_COMPLETION_LOG_SUBMIT_ERROR:
    case ActionTypes.EDIT_COMPLETION_LOG_SUBMIT_ERROR: {
      const newState = {
        ...state,
        saving: false,
      };
      return newState;
    }
  }
  return state;
};
