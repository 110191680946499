import React from "react";
import { Row, Col } from "react-bootstrap";

import Field from "./Field";

const InlineFields = ({
  displayItem,
  editable,
  prospectFormData,
  onFieldChange,
}) => {
  if (!displayItem.visible(prospectFormData)) return null;

  const leftField = (
    <Field
      editable={editable}
      displayItem={displayItem.leftField}
      prospectFormData={prospectFormData}
      displayContext="subform"
      onFieldChange={onFieldChange}
    />
  );
  const rightField = (
    <Field
      editable={editable}
      displayItem={displayItem.rightField}
      prospectFormData={prospectFormData}
      displayContext="subform"
      onFieldChange={onFieldChange}
    />
  );

  if (editable) {
    return (
      <Row>
        <Col sm={4}>{displayItem.label}</Col>
        <Col sm={8} className="d-flex">
          {displayItem.leftField.visible(prospectFormData) && (
            <span className="mr-3">{leftField}</span>
          )}
          {displayItem.rightField.visible(prospectFormData) && rightField}
        </Col>
      </Row>
    );
  } else {
    return (
      <Row>
        <dt className="mb-2 col-sm-4">{displayItem.label}</dt>
        <dd className="mb-0 col-sm-8">
          {displayItem.leftField.visible(prospectFormData) && (
            <span
              className="mr-3"
              data-testid={`${displayItem.leftField.fieldIdentifier}`}
            >
              {leftField}
            </span>
          )}
          {displayItem.rightField.visible(prospectFormData) && (
            <span data-testid={`${displayItem.rightField.fieldIdentifier}`}>
              {rightField}
            </span>
          )}
        </dd>
      </Row>
    );
  }
};

export default InlineFields;
