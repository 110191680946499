import React from "react";
import { Form, Row, Col } from "react-bootstrap";

import { EnumRadio } from "components/helpers";

const EnumRadioField = ({
  fieldName,
  label,
  options,
  value,
  errors,
  onFieldChange,
  suffix = undefined,
  inline = false,
  labelClassName,
}) => {
  const controlId = `text-field-${fieldName}`;

  const FormControl = (
    <EnumRadio
      fieldIdentifier={fieldName}
      suffix={suffix}
      options={options}
      value={value}
      onFieldChange={(fieldIdentifier, value) =>
        onFieldChange(fieldIdentifier, parseInt(value))
      }
      className={errors.length > 0 ? "is-invalid form-control pl-2" : ""}
    />
  );

  const ErrorFeedback = (
    <Form.Control.Feedback type="invalid">
      {errors.join(", ")}
    </Form.Control.Feedback>
  );

  if (inline) {
    return (
      <Form.Group as={Row} controlId={controlId}>
        <Form.Label column sm={4} className={labelClassName}>
          {label}
        </Form.Label>
        <Col sm={8}>
          {FormControl}
          {ErrorFeedback}
        </Col>
      </Form.Group>
    );
  } else {
    return (
      <Form.Group controlId={controlId}>
        <Form.Label className={labelClassName}>{label}</Form.Label>
        {FormControl}
        {ErrorFeedback}
      </Form.Group>
    );
  }
};

export default EnumRadioField;
