import { processRequest, dispatchWithTimeout } from "services/base_requests";

import { actions, ActionTypes } from "actions/management_report";
import { runReport, bulkContact } from "services/management_report_requests";

const ManagementReportMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    const successHandlerFor = dispatchWithTimeout(dispatch);
    const failureHandlerFor = dispatchWithTimeout(dispatch, "alert-danger");

    switch (action.type) {
      case ActionTypes.RUN_REPORT: {
        const request = runReport(
          action.studyId,
          action.site,
          action.timepoint,
          action.questionnaire,
          action.status,
          action.plannedMethod
        );
        const success = successHandlerFor(actions.runReportSuccess);
        const failure = failureHandlerFor(actions.runReportFailure);
        const error = failureHandlerFor(
          actions.runReportError,
          "Unable to run the report, please try again and contact support if the problem persists"
        );

        processRequest(request, success, failure, error);
        break;
      }

      case ActionTypes.RUN_BULK_CONTACT_UPDATE: {
        const request = bulkContact(
          action.studyId,
          action.selectedQuestionnaireInstances,
          action.contactType,
          action.contactDate,
          action.contactMethod,
          action.reminderYn,
          action.noReminderRsn,
          action.noCompRsn,
          action.site,
          action.timepoint,
          action.questionnaire,
          action.status,
          action.plannedMethod
        );
        const success = successHandlerFor(actions.runBulkContactUpdateSuccess);
        const failure = failureHandlerFor(
          actions.runBulkContactUpdateFailure,
          "Unable to update the contact logs, please try again and contact support if the problem persists"
        );

        processRequest(request, success, failure, failure);
        break;
      }
    }
    return next(action);
  };

export default ManagementReportMiddleware;
