import React from "react";

import { Table, Button } from "react-bootstrap";

import Field from "./Field";

const SubformRow = ({
  editable,
  prospectFormData,
  subformStructure,
  onSubformFieldChange,
  removeSubformRow,
  row,
  rowIndex,
}) => {
  const subformIdentifier = subformStructure.identifier;
  const handleSubformFieldChange = (fieldIdentifier, value) => {
    onSubformFieldChange(
      subformStructure.identifier,
      rowIndex,
      fieldIdentifier,
      value
    );
  };

  return (
    <tr
      id={`contact-log-row-${row.row_num}`}
      key={`${subformIdentifier}-row-${rowIndex}`}
    >
      <td>{row.row_num + 1}</td>
      {subformStructure.displayItems.map((item) => {
        return (
          <td key={`${item.fieldIdentifier}-${rowIndex}`}>
            <Field
              editable={editable}
              displayItem={item}
              prospectFormData={prospectFormData}
              displayContext="subform"
              subformIdentifier={subformIdentifier}
              rowIndex={rowIndex}
              onFieldChange={handleSubformFieldChange}
            />
          </td>
        );
      })}
      {editable && (
        <td>
          <Button
            size="sm"
            type="button"
            variant="light"
            onClick={() => {
              removeSubformRow(subformIdentifier, rowIndex);
            }}
          >
            <i className="fa fa-trash mr-1" title="Remove subform row" />
            Delete
          </Button>
        </td>
      )}
    </tr>
  );
};

const Subform = ({
  editable,
  displayItem,
  prospectFormData,
  onSubformFieldChange,
  addSubformRow,
  removeSubformRow,
}) => {
  if (!displayItem.visible(prospectFormData, 0)) return null;

  const title = displayItem.title;
  const subformIdentifier = displayItem.subformIdentifier;
  const subformStructure = prospectFormData.getSubformFor(subformIdentifier);
  const subformData = prospectFormData.getSubformRowsFor(subformIdentifier);

  return (
    <div className="subform">
      <p className="lead mb-1">{title}</p>
      <Table
        bordered
        data-testid={`subform-${subformIdentifier}`}
        className="mb-3"
      >
        <thead className="thead-light">
          <tr>
            <th></th>
            {subformStructure.fields.map((field) => {
              return <th key={`${field.identifier}-heading`}>{field.label}</th>;
            })}
            {editable && <th></th>}
          </tr>
        </thead>
        <tbody>
          {subformData.map((row, rowIndex) => {
            if (!row.marked_for_destruction)
              return (
                <SubformRow
                  key={`subform-row-${rowIndex}`}
                  editable={editable}
                  prospectFormData={prospectFormData}
                  subformStructure={subformStructure}
                  onSubformFieldChange={onSubformFieldChange}
                  removeSubformRow={removeSubformRow}
                  rowIndex={rowIndex}
                  row={row}
                />
              );
          })}
          {editable && (
            <tr>
              <td colSpan={subformStructure.fields.length + 2}>
                <Button
                  size="sm"
                  type="button"
                  variant="light"
                  className="float-right"
                  onClick={() => {
                    addSubformRow(subformIdentifier);
                  }}
                >
                  Add an entry
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default Subform;
