import { ActionTypes } from "actions/management_report";
import { getAllValues } from "services/reducer_helpers";
import { orderDates } from "services/date_helper";

const plannedMethodsForSelect = [
  { value: 0, label: "Email", identifier: "email" },
  { value: 2, label: "Post", identifier: "post" },
  { value: 3, label: "Telephone", identifier: "telephone" },
  { value: 1, label: "SMS", identifier: "sms" },
  { value: 4, label: "In Person", identifier: "inPerson" },
];

const blankBulkContactForm = {
  shown: false,
  baseErrors: [],
  selectedQuestionnaireInstanceErrors: [],
  contactType: { value: null, errors: [] },
  contactDate: { value: new Date(), errors: [] },
  contactMethod: { value: null, errors: [] },
  reminderYn: { value: null, errors: [] },
  noReminderRsn: { value: "", errors: [] },
  noCompRsn: { value: "", errors: [] },
};
const sortResultsByDueDate = (records) => {
  return [...records].sort((result1, result2) => {
    return orderDates(result1.dueDate, result2.dueDate);
  });
};

const plannedMethodsForQuestionnaires = (questionnaires) => {
  const allPossibleMeans = questionnaires.map((questionnaire) => {
    return questionnaire.meansOfDeliveryTypes;
  });
  const uniquePossibleMeans = [...new Set(allPossibleMeans.flat())];

  return uniquePossibleMeans.map((type) => {
    return plannedMethodsForSelect.find(
      (plannedMethod) => plannedMethod.identifier == type
    );
  });
};

const errorsDefaultState = {
  questionnaires: [],
  timepoints: [],
};

export const managementReportDefaultState = {
  studyId: undefined,
  availableQuestionnaires: [],
  availableSites: [],
  availableStatuses: [],
  availablePlannedMethods: plannedMethodsForSelect,
  results: [],
  resultsShown: false,
  selectedRecords: [],
  messages: [],
  site: [],
  timepointOptions: [],
  timepoint: undefined,
  questionnaire: undefined,
  status: [],
  plannedMethodOptions: [],
  plannedMethod: [],
  errors: errorsDefaultState,
  saving: false,
  bulkContactForm: blankBulkContactForm,
};

const resultsAndBulkUpdateHidden = {
  resultsShown: false,
  results: [],
  selectedRecords: [],
  bulkContactForm: blankBulkContactForm,
};

export const loadReportDataFrom = (
  studyId,
  availableSites,
  availableStatuses,
  availableQuestionnaires,
  availableTimepoints
) => {
  return {
    ...managementReportDefaultState,
    studyId: studyId,
    availableSites: availableSites,
    site: [],
    availableStatuses: availableStatuses,
    status: [],
    availableTimepoints: availableTimepoints,
    timepointOptions: availableTimepoints,
    plannedMethodOptions: plannedMethodsForQuestionnaires(
      availableQuestionnaires
    ),
    timepoint: getAllValues(availableTimepoints),
    availableQuestionnaires: availableQuestionnaires,
    questionnaire: getAllValues(availableQuestionnaires),
  };
};

export const ManagementReportReducer = (
  state = managementReportDefaultState,
  action
) => {
  switch (action.type) {
    case ActionTypes.UPDATE_FILTER_VALUE: {
      if (action.fieldIdentifier === "questionnaire") {
        // We include timepoints that are available at, at least one of the selected questionnaires
        const newTimepointOptions = state.availableTimepoints.filter((t) => {
          return t.questionnaires.some((questionnaire_identifier) =>
            action.value.includes(questionnaire_identifier)
          );
        });
        const newTimepoint = state.timepoint.filter((timepoint) =>
          newTimepointOptions
            .map((timepoint) => timepoint.value)
            .includes(timepoint)
        );

        const selectedQuestionnaires = state.availableQuestionnaires.filter(
          (questionnaire) => {
            return action.value.includes(questionnaire.value);
          }
        );

        const newPlannedMethodOptions = plannedMethodsForQuestionnaires(
          selectedQuestionnaires
        );
        const newPlannedMethod = state.plannedMethod.filter((plannedMethod) =>
          newPlannedMethodOptions
            .map((plannedMethod) => plannedMethod.value)
            .includes(plannedMethod)
        );

        return {
          ...state,
          ...resultsAndBulkUpdateHidden,
          questionnaire: action.value,
          timepointOptions: newTimepointOptions,
          timepoint: newTimepoint,
          plannedMethodOptions: newPlannedMethodOptions,
          plannedMethod: newPlannedMethod,
        };
      }

      const newState = {
        ...state,
        ...resultsAndBulkUpdateHidden,
        [action.fieldIdentifier]: action.value,
      };
      return newState;
    }

    case ActionTypes.RUN_REPORT: {
      const newState = {
        ...state,
        ...resultsAndBulkUpdateHidden,
        errors: errorsDefaultState,
        saving: true,
      };
      return newState;
    }

    case ActionTypes.RUN_REPORT_SUCCESS: {
      const newState = {
        ...state,
        saving: false,
        resultsShown: true,
        results: sortResultsByDueDate(action.responseData.records),
      };
      return newState;
    }

    case ActionTypes.RUN_REPORT_FAILURE: {
      const newState = {
        ...state,
        errors: { ...errorsDefaultState, ...action.responseData.errors },
        saving: false,
      };
      return newState;
    }

    case ActionTypes.RUN_REPORT_ERROR: {
      // TODO: What should we do in the case of a failure?
      const newState = { ...state, saving: false };
      return newState;
    }

    case ActionTypes.TOGGLE_RECORD_SELECTION: {
      let newSelectedRecords;
      if (action.checked) {
        newSelectedRecords = [...state.selectedRecords, action.record];
      } else {
        newSelectedRecords = state.selectedRecords.filter(
          (record) => record !== action.record
        );
      }

      let newBulkContactForm;
      if (newSelectedRecords.length > 0) {
        newBulkContactForm = { ...state.bulkContactForm, shown: true };
      } else {
        newBulkContactForm = blankBulkContactForm;
      }

      return {
        ...state,
        selectedRecords: sortResultsByDueDate(newSelectedRecords),
        bulkContactForm: newBulkContactForm,
      };
    }

    case ActionTypes.SELECT_ALL_RECORDS: {
      const newState = {
        ...state,
        selectedRecords: state.results,
        bulkContactForm: { ...state.bulkContactForm, shown: true },
      };
      return newState;
    }

    case ActionTypes.CLEAR_RECORD_SELECTION: {
      const newState = {
        ...state,
        selectedRecords: [],
        bulkContactForm: blankBulkContactForm,
      };
      return newState;
    }

    case ActionTypes.DISMISS_FLASH_MESSAGE: {
      const newMessages = state.messages.filter(
        (message, index) => index !== action.messageIndex
      );
      const newState = { ...state, messages: newMessages };
      return newState;
    }

    case ActionTypes.UPDATE_BULK_CONTACT_VALUE: {
      if (action.fieldIdentifier === "contactType") {
        // Reset the contactDate and contactMethod
        return {
          ...state,
          bulkContactForm: {
            ...state.bulkContactForm,
            contactType: {
              ...state.bulkContactForm[action.fieldIdentifier],
              value: action.value,
            },
            contactDate: { value: new Date(), errors: [] },
            contactMethod: { value: null, errors: [] },
          },
        };
      } else {
        return {
          ...state,
          bulkContactForm: {
            ...state.bulkContactForm,
            [action.fieldIdentifier]: {
              ...state.bulkContactForm[action.fieldIdentifier],
              value: action.value,
            },
          },
        };
      }
    }

    case ActionTypes.RUN_BULK_CONTACT_UPDATE: {
      const newState = {
        ...state,
        saving: true,
      };
      return newState;
    }

    case ActionTypes.RUN_BULK_CONTACT_UPDATE_SUCCESS: {
      const updatedRecords = action.responseData.updated_records;
      const updatedRecordIdentifiers = updatedRecords.map(
        (result) => result.recordIdentifier
      );

      const removedRecords = action.responseData.removed_records;
      const removedRecordIdentifiers = removedRecords.map(
        (result) => result.recordIdentifier
      );

      const resultsAfterRemoval = state.results.filter((result) => {
        return !removedRecordIdentifiers.includes(result.recordIdentifier);
      });

      const newResults = resultsAfterRemoval.map((result) => {
        if (updatedRecordIdentifiers.includes(result.recordIdentifier)) {
          return updatedRecords.find(
            (new_result) =>
              result.recordIdentifier === new_result.recordIdentifier
          );
        } else {
          return result;
        }
      });

      const newState = {
        ...state,
        saving: false,
        results: sortResultsByDueDate(newResults),
        selectedRecords: [],
        bulkContactForm: blankBulkContactForm,
      };
      return newState;
    }

    case ActionTypes.RUN_BULK_CONTACT_UPDATE_FAILURE: {
      const newState = {
        ...state,
        saving: false,
        bulkContactForm: {
          ...state.bulkContactForm,
          baseErrors: action.responseData.base || [],
          selectedQuestionnaireInstanceErrors:
            action.responseData.selected_questionnaire_instances || [],
          contactDate: {
            ...state.bulkContactForm.contactDate,
            errors: action.responseData.contact_date || [],
          },
          contactMethod: {
            ...state.bulkContactForm.contactMethod,
            errors: action.responseData.contact_method || [],
          },
          reminderYn: {
            ...state.bulkContactForm.reminderYn,
            errors: action.responseData.reminder_yn || [],
          },
        },
      };
      return newState;
    }
  }
  return state;
};
