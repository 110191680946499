/* eslint no-console:0 */

// Bootstrap and application styles
import "bootstrap";
import "@fortawesome/fontawesome-pro/css/all";
import "./application.scss";
import "styles/prospect_flatpickr.scss";

// Images
require.context("../images", true);

// Rails support
import Rails from "rails-ujs";
Rails.start();

// Custom modal styling
require("data-confirm-modal");
dataConfirmModal.setDefaults({
  title: "Are you sure?",
  commit: "Yes",
  commitClass: "btn-primary",
  cancel: "No",
  cancelClass: "btn-outline-secondary cancel",
  focus: "commit",
  modalClass: "modal-confirm-dialog",
  fade: process.env.NODE_ENV !== "test",
});

// Initialise jQuery
import $ from "jquery";
import "jquery.are-you-sure";

// Report errors to Sentry
import * as Sentry from "@sentry/browser";
if (process.env.NODE_ENV === "production") {
  const sentryDsn = $('meta[name="sentry-dsn"]').attr("content");
  Sentry.init({ dsn: sentryDsn });
}

$(() => {
  // Dismiss flash messages after 10 seconds
  if ($(".flash-messages .alert.auto-hide").length > 0) {
    setTimeout(() => {
      $(".flash-messages .alert.auto-hide").alert("close");
    }, 10000);
  }

  // Warn when leaving a page without submitting
  $("form").areYouSure({
    message:
      "Changes you have made to this form will not be saved; are you sure you want to leave this form?",
  });
});

import { VisibilityMap } from "@epigenesys/epi-js";

// Support component names relative to the 'pages' directory
var pagesContext = require.context("pages", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(pagesContext);
