import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actions } from "actions/management_report";

import { Row, Col, Form } from "react-bootstrap";
import { ButtonWithSpinner } from "components/helpers";
import { SelectField } from "components/helpers/fields";
import CSVDownload from "./CSVDownload";

const ReportForm = ({
  studyId,
  saving,
  errors,
  availableSites,
  availableQuestionnaires,
  availableStatuses,
  plannedMethodOptions,
  timepointOptions,
  site,
  questionnaire,
  timepoint,
  status,
  plannedMethod,
  updateFilterValue,
  runReport,
}) => {
  const handleFormSubmit = (e) => {
    e.preventDefault();
    runReport(studyId, site, timepoint, questionnaire, status, plannedMethod);
  };

  return (
    <Form onSubmit={handleFormSubmit}>
      <Row>
        <Col md={6}>
          <SelectField
            fieldName="site"
            label="Site"
            placeholder="- All sites -"
            options={availableSites}
            value={site}
            onFieldChange={updateFilterValue}
            isMulti={true}
            isClearable={true}
            required={true}
            errors={[]}
            selectAllHandler={() => {
              updateFilterValue("site", []);
            }}
          />
        </Col>
        <Col md={6}>
          <SelectField
            fieldName="status"
            label="Status"
            placeholder="- All statuses -"
            options={availableStatuses}
            value={status}
            onFieldChange={updateFilterValue}
            isMulti={true}
            isClearable={true}
            required={true}
            errors={[]}
            selectAllHandler={() => {
              updateFilterValue("status", []);
            }}
          />
        </Col>

        <Col md={6}>
          <SelectField
            fieldName="questionnaire"
            label="Questionnaire"
            options={availableQuestionnaires}
            value={questionnaire}
            onFieldChange={updateFilterValue}
            isMulti={true}
            isClearable={true}
            required={true}
            errors={errors.questionnaires}
            selectAllHandler={() => {
              updateFilterValue(
                "questionnaire",
                availableQuestionnaires.map(
                  (questionnaire) => questionnaire.value
                )
              );
            }}
          />
        </Col>

        <Col md={6}>
          <SelectField
            fieldName="plannedMethod"
            label="Planned Method"
            placeholder="- All planned methods -"
            options={plannedMethodOptions}
            value={plannedMethod}
            onFieldChange={updateFilterValue}
            isMulti={true}
            isClearable={true}
            required={true}
            errors={[]}
            selectAllHandler={() => {
              updateFilterValue("plannedMethod", []);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <SelectField
            fieldName="timepoint"
            label="Timepoint"
            options={timepointOptions}
            value={timepoint}
            onFieldChange={updateFilterValue}
            isMulti={true}
            isClearable={true}
            required={true}
            errors={errors.timepoints}
            selectAllHandler={() => {
              updateFilterValue(
                "timepoint",
                timepointOptions.map((timepoint) => timepoint.value)
              );
            }}
          />
        </Col>
      </Row>

      <div className="d-flex gap-2 justify-content-end">
        <CSVDownload />
        <ButtonWithSpinner
          saving={saving}
          type="submit"
          buttonText="Run report"
          savingText="Loading..."
        />
      </div>
    </Form>
  );
};

const mapStateToProps = (state) => {
  let relevantState = state.managementReport;

  return {
    studyId: relevantState.studyId,
    saving: relevantState.saving,
    errors: relevantState.errors,
    availableSites: relevantState.availableSites,
    availableQuestionnaires: relevantState.availableQuestionnaires,
    availableStatuses: relevantState.availableStatuses,
    plannedMethodOptions: relevantState.plannedMethodOptions,
    timepointOptions: relevantState.timepointOptions,
    site: relevantState.site,
    questionnaire: relevantState.questionnaire,
    timepoint: relevantState.timepoint,
    status: relevantState.status,
    plannedMethod: relevantState.plannedMethod,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateFilterValue: actions.updateFilterValue,
      runReport: actions.runReport,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportForm);
