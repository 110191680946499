import React from "react";
import { Form, Row, Col } from "react-bootstrap";

import { DatePicker } from "components/helpers";

const DateField = ({
  fieldName,
  label,
  value,
  errors,
  onFieldChange,
  inline = false,
  inputClassName,
  labelClassName,
}) => {
  const FormControl = (
    <DatePicker
      fieldIdentifier={fieldName}
      value={value}
      errors={errors}
      onFieldChange={onFieldChange}
      inputClassName={`form-control${
        inputClassName ? ` ${inputClassName}` : ""
      }`}
    />
  );

  const ErrorFeedback = (
    <Form.Control.Feedback type="invalid">
      {errors.join(", ")}
    </Form.Control.Feedback>
  );

  if (inline) {
    return (
      <Form.Group as={Row} controlId={fieldName}>
        <Form.Label column sm={4} className={labelClassName}>
          {label}
        </Form.Label>
        <Col sm={8}>
          {FormControl}
          {ErrorFeedback}
        </Col>
      </Form.Group>
    );
  } else {
    return (
      <Form.Group controlId={fieldName}>
        <Form.Label className={labelClassName}>{label}</Form.Label>
        {FormControl}
        {ErrorFeedback}
      </Form.Group>
    );
  }
};

export default DateField;
