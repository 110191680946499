import React from "react";

import { Form, Button } from "react-bootstrap";

const EnumRadio = ({
  fieldIdentifier,
  options,
  value,
  className = "",
  inline = true,
  suffix = undefined,
  onFieldChange,
}) => {
  return (
    <>
      <div
        key="inline-radio"
        className={`radio-control${
          className.length > 0 ? ` ${className}` : ""
        }`}
        style={{ width: "max-content" }}
      >
        {options.map((option) => {
          return (
            <Form.Check
              key={option.value}
              checked={value === option.value}
              inline={inline}
              label={option.label}
              name={
                suffix ? `${fieldIdentifier}-${suffix}` : `${fieldIdentifier}`
              }
              value={option.value}
              type="radio"
              id={
                suffix
                  ? `${fieldIdentifier}-${option.label}-${suffix}`
                  : `${fieldIdentifier}-${option.label}`
              }
              onChange={(e) => {
                onFieldChange(fieldIdentifier, e.target.value);
              }}
            />
          );
        })}
        <Button
          variant="link"
          size="sm"
          className="pl-0"
          onClick={() => {
            onFieldChange(fieldIdentifier, null);
          }}
        >
          Clear
        </Button>
      </div>
    </>
  );
};

export default EnumRadio;
