import React from "react";
import { Alert } from "react-bootstrap";

const FlashMessage = ({ message, index, onDismiss }) => {
  return (
    <>
      <Alert show={true} transition={false} variant="warning">
        <div className="container">
          <i className="fas fa-exclamation-triangle mr-1 flash-error" />
          {message}
          <a
            href="#"
            className="ml-1"
            title="Hide this message"
            onClick={() => onDismiss(index)}
          >
            Dismiss
          </a>
        </div>
      </Alert>
    </>
  );
};

export default FlashMessage;
